<template>
	<div>
		<v-select
			@input="(v) => $emit('update', 'video_medium', v)"
			:value="appointment.video_medium"
			label="Video Medium"
			:items="video_mediums"
		>
		</v-select>
	</div>
</template>

<script>
export default {
	name: "EditVideoAppointment",
	props: {
		id: { type: String },
	},
	computed: {
		video_mediums() {
			return this.$store.state.video_mediums;
		},
		appointment() {
			return this.$store.state.appointments.data[this.id];
		},
		videoRoom() {
			if (this.appointment && this.appointment.video_room) {
				return this.$store.state.videoRooms.data[
					this.appointment.video_room
				];
			}
			return false;
		},
	},
	methods: {
		getOrCreateLink() {
			if (this.appointment.video_room) {
				return `/video/${this.appointment.video_room}`;
			} else {
				this.$store.dispatch("videoRooms/insert", {
					start: this.appointment.start,
				});
			}
		},
	},
};
</script>
