<template>
	<v-sheet color="transparent">
		<v-btn color="primary" block @click="newParticipant" class="mb-6">Add participant</v-btn>
		<v-autocomplete
			small-chips
			color="darkest"
			label="Methodology"
			:value="appointment.methodology"
			@input="(v) => update('methodology', v)"
			:items="getItems('methodology')"
		></v-autocomplete>
		<v-autocomplete
			v-if="project"
			small-chips
			color="darkest"
			label="Appointment Quota"
			:value="appointment.quota"
			@input="(v) => update('quota', v)"
			:items="getItems('quota')"
		></v-autocomplete> 
		<mw-date-picker
			label="Appointment Start"
			:value="appointment.start"
			@input="(v) => update('start', v)"
		></mw-date-picker>
		<v-text-field
			@input="(v) => update('participation_link', v)"
			:value="appointment.participation_link"
			label="Participation Link"
		>
		</v-text-field>
		<edit-video-appointment @update="update"
			v-if="appointment.methodology == 'video'"
			:id="id"
		>
		</edit-video-appointment>
	</v-sheet>
</template>

<script>
import MwDatePicker from "@c/ui/MwDatePicker";
import Appointment from "@/models/Appointment";
import EditVideoAppointment from "@c/appointment-table/EditVideoAppointment.vue";
export default {
	name: "EditAppointment",
	props: {
		id: { type: String },
	},
	inject: ["projectId"],
	computed: {
		model() {
			return Appointment;
		},
		project() {
			return this.$store.state.projects.data[this.projectId];
		},
		appointment() {
			if (!this.id) {
				let appointment = this.mwutils.getEmpty(this.model);
				for (var key in this.prefill) {
					appointment[key] = this.prefill[key];
				}
			}
			return this.$store.state.appointments.data[this.id];
		},
	},
	methods: {
		update(key, v) {
			const self = this;
			this.$store.dispatch("appointments/patch", { id: this.id, [key]: v }).then( () => {
				self.$root.$emit("showToast", "Saved");
			})
		},
		getItems(key) {
			let str = `${this.model[key].try_items}`;
			let try_items = this.mwutils.byString(this, str);
			return try_items || this.model[key].items || [];
		},
		newParticipant(){
			const self = this;
			self.$store.dispatch("appointmentParticipants/insert", { appointment: self.id }).then( (id) => {
				self.$emit("addParticipant", id )
			})
		}
	},
	components: {
		MwDatePicker,
		EditVideoAppointment,
	},
};
</script>
